<template lang="pug">
div
  fi-data-field(:label = '$t("reportingType")' :label-cols = 3 ) {{ classifierById('TYP_PRTCTN', addData.assetReportingType).human }}
  .flex-row-direction
    .loan-collateral-asset-trade-receivables-fields-display.animated.fadeIn.width-50.margin-right-20
      fi-data-field(:label = '$t("registerNumber")') {{ addData.registerNumber }}
      fi-data-field(:label = '$t("type")') {{ classifierById('warrantyTypes', addData.warrantyTypeId).human }}
      fi-data-field(v-if   = 'isShowName'
                    :label = '$t("immovableName")') {{ addData.immovableName }}
      fi-data-field(:label = '$t("warrantyCadastralUnitIdentifier")') {{ addData.warrantyCadastralUnitIdentifier }}
      fi-data-field(:label = '$t("country")') {{ classifierById('countries', addData.warrantyCountryId).human }}
      fi-data-field(:label = '$t("county")') {{ classifierById('counties', addData.warrantyCountyId).human }}
      fi-data-field(:label = '$t("city")') {{ addData.warrantyCity }}
      fi-data-field(:label = '$t("address")') {{ addData.warrantyAddress }}
      fi-data-field(:label = '$t("postalСode")') {{ addData.warrantyPostalCode }}
      fi-data-field(:label = '$t("warrantyOwnerName")')
        .flex-column-direction
          <table v-if = 'addData.assetsOwners' style="width:100%">
            template(v-for = '(owner, index) in addData.assetsOwners')
              <tr>
                <td class = 'bold' style="border:none, padding:0px"> {{ owner.customerName }} </td>
              </tr>
          </table>
      fi-data-field(:label = '$t("assetInfo")')
        .text(v-html = 'sanitizedText')
      fi-data-field(:label = '$t("internalComment")')
        .text(v-html = 'sanitizedCommentText')
    div.width-50
      fi-data-field(:label = '$t("valueType")') {{ classifierById('TYP_PRTCTN_VL', addData.valueType).human }}
      fi-data-field(:label = '$t("valuationType")') {{ classifierById('PRTCTN_VLTN_APPRCH', addData.valuationType).human }}
      fi-data-field(:label = '$t("locationCode")') {{ classifierById('ISO3166_NUTS_DSJNT', addData.collateralLocationCode).human }}
      fi-data-field(:label = '$t("assetStatus")') {{ addData.assetStatus }}
      //- fi-data-field(:label = '$t("appraisalPartner")') {{ customerName(appraisalPartnerData) }}
      fi-data-field(:label = '$t("assetSoldValue")') {{ addData.assetSoldValue }}
      fi-data-field(:label = '$t("soldDate")') {{ $t('common:formatDate', { value: addData.soldDate }) }}
      fi-data-field(:label = '$t("warrantyArea")')
        .row
          .col {{ addData.warrantyArea }}
          .col {{ classifierById('immovableAreaSizeType', addData.areaSizeTypeId).human }}
      fi-data-field(:label = '$t("value")') {{ assetData.value | money }}
      fi-data-field(:label = '$t("valueDate")') {{ $t('common:formatDate', { value: assetData.valueDate }) }}
      fi-data-field(:label = '$t("allocatedValue")') {{ assetData.allocatedValue | money }}
      fi-data-field(:label = '$t("appraisalReport")') {{ assetData.appraisalReport ? $t('common:yes') : $t('common:no') }}
      template(v-if = 'assetData.appraisalReport')
        fi-data-field(:label = '$t("appraisalPartnerName")') {{ assetData.appraisalPartnerName }}
        fi-data-field(:label = '$t("appraisalValue")') {{ assetData.appraisalValue }}
      fi-data-field(:label = '$t("insuranceMandatory")') {{ assetData.insuranceMandatory ? $t('common:yes') : $t('common:no') }}
      fi-data-field(:label = '$t("insuranceCompany")') {{ assetData.insuranceCompany }}
      fi-data-field(:label = '$t("insuredUntil")') {{ $t('common:formatDate', { value: addData.collateralLocationCode }) }}
      fi-data-field(:label = '$t("insuredUntil")') {{ $t('common:formatDate', { value: assetData.insuredUntil }) }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import { mapGetters } from 'vuex'
import sanitizeHtml from 'sanitize-html'
import { customerName } from '@/helpers'

export default {
  name: 'loan-collateral-asset-trade-receivables-fields-display',

  components: { FiDataField },

  props: {
    addData: {
      type: Object,
      required: true
    },
    assetData: {
      type: Object,
      required: true
    },
    appraisalPartnerData: {
      type: Object,
      default: null
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    sanitizedText () {
      return sanitizeHtml(this.assetData.assetInfo || '', {
        allowedTags: false,
        allowedAttributes: false,
        allowedSchemes: ['data', 'http'],
        nonTextTags: ['style', 'script', 'textarea', 'noscript']
      })
    },
    sanitizedCommentText () {
      return sanitizeHtml(this.assetData.comment || '', {
        allowedTags: false,
        allowedAttributes: false,
        allowedSchemes: ['data', 'http'],
        nonTextTags: ['style', 'script', 'textarea', 'noscript']
      })
    },
    isShowName () {
      return /land|lot/i.test(this.classifierById('warrantyTypes', this.addData.warrantyTypeId).code)
    }
  },
  methods: {
    customerName
  }
}
</script>

<style lang="scss" scoped>
.flex-row-direction {
  display: flex;
}
.flex-column-direction {
  display: flex; flex-direction: column;
}
.bold {
  font-weight: bold;
  border: none !important;
}
.cursor-pointer {
  cursor: pointer;
}
.justify-content-space-evenly {
  justify-content: space-evenly;
}
.width-50 {
  width: 50%;
}
.margin-right-20 {
  margin-right: 20px
}
</style>


<i18n>
en:
  registerNumber:                  "Register number"
  type:                            "Immovable type"
  immovableName:                   "Immovable name"
  country:                         "Country"
  county:                          "County"
  city:                            "City"
  address:                         "Address"
  value:                           "Value"
  valueDate:                       "Value date"
  allocatedValue:        "Allocated value"
  appraisalReport:                 "Appraisal report"
  appraisalPartnerName:            "Appraisal partner"
  appraisalValue:                  "Appraisal value"
  insuranceMandatory:              "Insurance mandatory"
  insuranceCompany:                "Insurance company"
  insuredUntil:                    "Insured until"
  assetInfo:                       "Asset info"
  internalComment:                 "Internal comments"
  warrantyCadastralUnitIdentifier: "Cadastral unit identifier"
  warrantyArea:                    "Area"
  warrantyOwnerName:               "Owner name"
  postalСode:                      "Postal code"
  soldDate:                        "Sold date"
  assetSoldValue:                  "Asset sold value"
  appraisalPartner:                "Appraisal partner"
  reportingType:                   "Reporting type"
  valueType:                       "Value type"
  valuationType:                   "Valuation type"
  locationCode:                    "Location code"
  assetStatus:                     "Asset status"
et:
  registerNumber:                  "Register number"
  type:                            "Immovable type"
  immovableName:                   "Immovable name"
  country:                         "Country"
  county:                          "County"
  city:                            "City"
  address:                         "Address"
  value:                           "Value"
  valueDate:                       "Value date"
  allocatedValue:        "Allocated value"
  appraisalReport:                 "Appraisal report"
  appraisalPartnerName:            "Appraisal partner"
  appraisalValue:                  "Appraisal value"
  insuranceMandatory:              "Insurance mandatory"
  insuranceCompany:                "Insurance company"
  insuredUntil:                    "Insured until"
  assetInfo:                       "Asset info"
  internalComment:                 "Internal comments"
  warrantyCadastralUnitIdentifier: "Cadastral unit identifier"
  warrantyArea:                    "Area"
  warrantyOwnerName:               "Owner name"
  postalСode:                      "Postal code"
  soldDate:                        "Sold date"
  assetSoldValue:                  "Asset sold value"
  appraisalPartner:                "Appraisal partner"
  reportingType:                   "Reporting type"
  valueType:                       "Value type"
  valuationType:                   "Valuation type"
  locationCode:                    "Location code"
  assetStatus:                     "Asset status"
ru:
  registerNumber:                  "Register number"
  type:                            "Immovable type"
  immovableName:                   "Immovable name"
  country:                         "Country"
  county:                          "County"
  city:                            "City"
  address:                         "Address"
  value:                           "Value"
  valueDate:                       "Value date"
  allocatedValue:        "Allocated value"
  appraisalReport:                 "Appraisal report"
  appraisalPartnerName:            "Appraisal partner"
  appraisalValue:                  "Appraisal value"
  insuranceMandatory:              "Insurance mandatory"
  insuranceCompany:                "Insurance company"
  insuredUntil:                    "Insured until"
  assetInfo:                       "Asset info"
  internalComment:                 "Internal comments"
  warrantyCadastralUnitIdentifier: "Cadastral unit identifier"
  warrantyArea:                    "Area"
  warrantyOwnerName:               "Owner name"
  postalСode:                      "Postal code"
  soldDate:                        "Sold date"
  assetSoldValue:                  "Asset sold value"
  appraisalPartner:                "Appraisal partner"
  reportingType:                   "Reporting type"
  valueType:                       "Value type"
  valuationType:                   "Valuation type"
  locationCode:                    "Location code"
  assetStatus:                     "Asset status"
</i18n>
